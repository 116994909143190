:root {
    --cassette-color: #F0E226;
    --spacing-unit: 8px;
}

@font-face {
    font-family: "Apercu";
    font-weight: normal;
    font-style: normal;
    src: local("Apercu"),
    url(/static/media/Apercu.75a73701.otf) format("opentype");
}

@font-face {
    font-family: "Apercu";
    font-weight: 500;
    font-style: normal;
    src: local("Apercu"),
    url(/static/media/Apercu-Medium.7bbdc2e3.otf) format("opentype");
}

@font-face {
    font-family: "ApercuMono";
    font-style: normal;
    src: local("Apercu"),
    url(/static/media/Apercu-Mono.5caa1465.otf) format("opentype");
}

@font-face {
    font-family: "Apercu";
    font-weight: bold;
    font-style: normal;
    src: local("Apercu-Bold"),
    url(/static/media/Apercu-Bold.b62ffe75.otf) format("opentype");
}

@font-face {
    font-family: "Apercu";
    font-weight: lighter;
    font-style: lighter;
    src: local("Apercu-Light"),
    url(/static/media/Apercu-Light.89445653.otf) format("opentype");
}

@font-face {
    font-family: "Apercu";
    font-weight: normal;
    font-style: italic;
    src: local("Apercu-Italic"),
    url(/static/media/Apercu-Italic.9fe05bf5.otf) format("opentype");
}


@font-face {
    font-family: "Apercu";
    font-weight: lighter;
    font-style: italic;
    src: local("Apercu-LightItalic"),
    url(/static/media/Apercu-LightItalic.a5d16dcb.otf) format("opentype");
}

@font-face {
    font-family: "Apercu";
    font-weight: bold;
    font-style: italic;
    src: local("Apercu-BoldItalic"),
    url(/static/media/Apercu-BoldItalic.bf5e43bc.otf) format("opentype");
}

html,
body,
#app {
    height: 100%;
    box-sizing: border-box;
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
    line-height: 1;
    background-color: #222129;
    font-family: "Apercu", sans-serif;
    font-weight: normal;
    /* Leave out for react-select */
    /* color: white; */
}

.nav-link {
    display: flex;
    align-content: center;
    padding: 0 28px;
    margin: 8px 0px;

    color: white;
    font-size: 14px;

    cursor: pointer;
}

.nav-link:hover {
    color: #F0E226;
    color: var(--cassette-color);
}

.nav-active-link, .nav-active-link:hover {
    color: #F0E226;
    color: var(--cassette-color);
    text-decoration: none;
    border-left: 3px solid #F0E226;
    border-left: 3px solid var(--cassette-color);
    padding-left: 25px;
}

.nav-active-link-sub, .nav-active-link-sub:hover {
    padding-left: 35px !important;
}

.nav-link-sub {
    padding-left: 38px;
    padding-right: 2px;
}

.fa-thumbtack:hover {
    color: #F0E226 !important;
    color: var(--cassette-color) !important;
}

/* notification */
.alert {
    min-width: 300px;
}

/* put a hover-button in a hover-block to show on hover */
.hover-button {
    visibility: hidden;
}

.hover-block:hover .hover-button {
    visibility: visible;
}

/* Table modifications */
.dim-cell {
    white-space: pre-wrap;
    color: grey;
    text-align: left;
}

/* dot indicators */
.dot-status {
    width: 10px;
    height: 10px;
    border-radius: 5px;
}

.dot-status-online {
    background-color: #00FF00;
}

.dot-status-offline {
    background-color: red;
}

.dot-status-inactive {
    background-color: lightgray;
}

.dot-status-none {
    border: 1px solid #555;
}

.flash-in {
    -webkit-animation: flash 1s linear 1;
            animation: flash 1s linear 1;
}

@-webkit-keyframes flash {
    0% {
        background-color: #F0E226;
        background-color: var(--cassette-color);
    }
    100% {
        background-color: transparent;
    }
}

@keyframes flash {
    0% {
        background-color: #F0E226;
        background-color: var(--cassette-color);
    }
    100% {
        background-color: transparent;
    }
}

.center {
    display: flex;
    justify-content: center;
}

/* Hide select dropdown */
.select-hide-options .Select-arrow-zone {
    display: none;
}

.select-hide-options .Select-menu-outer {
    display: none;
}

#app-container {
    height: 100%;
    width: 100%;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #22222250;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #ffffff30;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.drag-hover {
    cursor: copy;
    border-radius: 3px;
    outline: 2px solid #00FF00;
    outline-offset: -2px;
}

.react-h5-audio-player {
    display: flex;
    flex: 1 1;
}

.react-h5-audio-player .flex {
    background-color: transparent !important;
    box-shadow: unset !important;
}

.react-h5-audio-player .toggle-play-wrapper .toggle-play-button {
    background-color: #F0E226 !important;
    background-color: var(--cassette-color) !important;
}

.toggle-play-button .pause-icon {
    box-shadow: #F0E226 7px 0px 0px 0px inset !important;
    box-shadow: var(--cassette-color) 7px 0px 0px 0px inset !important;
}

.progress-bar-wrapper .indicator {
    background-color: #F0E226 !important;
    background-color: var(--cassette-color) !important;
}

.audio-info {
    color: white;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
    width: 100%;
}

.react-datepicker__time-container {
    float: none !important;
    width: 90px !important;
}

.react-datepicker__time-container
.react-datepicker__time
.react-datepicker__time-box {
    width: 100% !important;
    margin: 0 !important;
}

.rt-td {
    overflow: visible !important;
}


.react-contextmenu {
    background-color: #dfe3e8;
    color: black;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    font-size: 16px;
    margin: 2px 0 0;
    min-width: 160px;
    outline: none;
    opacity: 0;
    padding: 5px 0;
    pointer-events: none;
    text-align: left;
    -webkit-transition: opacity 250ms ease !important;
    transition: opacity 250ms ease !important;
    z-index: 99999
}

.wrapper-context-menu, .wrapper-content-menu:hover {
    background-color: #222222;
    border: 1px solid #555555;
    padding: 0;
}

.react-contextmenu-item {
    background: 0 0;
    border: 0;
    color: black;
    background-color: #dfe3e8;
    cursor: pointer;
    font-weight: 400;
    line-height: 1.5;
    padding: 15px;
    text-align: inherit;
    white-space: nowrap;
}

.wrapper-context-menu .react-contextmenu-item {
    background-color: #222222;

    padding: 0;
    padding-top: 15px;
}

.react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
}

.react-contextmenu-item {
    background: 0 0;
    border: 0;
    color: black;
    cursor: pointer;
    font-weight: 400;
    line-height: 1.5;
    padding: 15px;
    text-align: inherit;
    white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
    color: black;
    background-color: #d0d5da;
    border-color: #d0d5da;
    text-decoration: none;
}

.wrapper-context-menu .react-contextmenu-item.react-contextmenu-item--active,
.wrapper-context-menu .react-contextmenu-item.react-contextmenu-item--selected {
    background-color: #222222;
    border-color: #222222;
    text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
    color: #878a8c;
}

.react-contextmenu-item--divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    cursor: inherit;
    margin-bottom: 3px;
    padding: 2px 0;
}

.react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
    padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu
> .react-contextmenu-item:after {
    content: "\25B6";
    display: inline-block;
    position: absolute;
    right: 7px;
}

.example-multiple-targets::after {
    content: attr(data-count);
    display: block;
}

.modal-pick .modal-content {
    background-color: #2c2b34;
}

.modal-pick .modal-header, .modal-pick .modal-footer {
    border-color: #464645;
}

.modal-pick button.close {
    color: white;
}

.btn-primary:not(:disabled):not(.disabled):active {
    border: 0;
    background-color: transparent;
    border-color: unset;
}

.Collapsible {
    width: 100%;
}


/* page layout */
.page {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.samplepage {
    position: fixed;
    /* center the element */
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    /* give it dimensions */
    height: 100%;
}

.page > .side {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 300px;
    background-color: #1B1A20;
}

button:focus {
    outline: 0;
}

.accordion__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: whitesmoke;
    cursor: pointer;
    height: 32px;
    width: 100%;
    text-align: left;
    font-weight: 500;
    border: none;
    outline: 0;
    padding-left: 28px;
    font-size: 14px;
}

.accordion__button:after {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 18px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true']::after,
.accordion__button[aria-selected='true']::after {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.accordion__panel {
    /* padding: 0 0 0 14px; */
}

.rowNew > *:hover {
    background: yellow !important;
}

.playingSong {
    color: #F0E226;
    color: var(--cassette-color);
}

.noClick {
    pointer-events: none;
}

.example1 {
    background-color: #00FF00;
}

.example2 {
    font-weight: bold;
}

.table_cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 10px 10px 0;
}

.table_cell_dim {
    opacity: .7;
}

.table_cell_hide {
    display: none;
}

.virtual-list {
    font-size: 14px;
    color: white;
}

.table_hover_padding, .table_hover_padding:hover {
    height: 42px;
    /* transition: all 200ms linear; */
    cursor: pointer;
    background-color: transparent;
}

.table_hover_padding:hover {
    background-color: #3A3843;
}

.highlight {
    /* when hovering on highlighed row, do not change color */
    background-color: #3D3A49 !important;
}

.history-added {
    /* when hovering on highlighed row, do not change color */
    color: #aae8aa !important;
    background-color: #182418 !important;
}

.history-removed {
    /* when hovering on highlighed row, do not change color */
    color: #C67474 !important;
    background-color: #221014 !important;
}

.onRowHover {
    display: none;
}

.table_hover_padding:hover .onRowHover {
    display: block !important;
}

.table_hover, .table_hover:hover {
    height: 42px;
    margin: 0 0 0 0;
    padding: 0 0;
    box-sizing: border-box;
    -webkit-transition: all 250ms ease-out;
    transition: all 250ms ease-out;
    cursor: pointer;
    background-color: transparent;
}

.table_hover:hover {
    margin: 0;
    padding: 0 0;
    border-radius: 0;
    background-color: #3A3843;
}

.song_playing {
    color: #F0E226;
    color: var(--cassette-color);
}

input[type='range'] {
    height: 24px;
    overflow: hidden;
    cursor: pointer;
    background: transparent;
}

input[type=range]:focus {
    outline: none;
}

input[type='range'],
input[type='range']::-webkit-slider-runnable-track,
input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
}

input[type='range']::-webkit-slider-runnable-track {
    width: 200px;
    height: 3px;
    background: grey;
    border-radius: 5px;
}

input[type='range']::-webkit-slider-thumb {
    position: relative;
    height: 12px;
    width: 12px;
    margin-top: -4px;
    background: white;
    border-radius: 50%;
}

::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    background-color: #222129;
}


.animate-song-details-enter .container {
    background-color: yellow;
}

.animate-song-details-done .container {
    background-color: red;
}

.animate-song-details-exit .container {
    background-color: blue;
}

.w-split-line-bar ::after {
    background: #4A494E !important;
}

.backdrop-second-level {
    z-index: 10002 !important;
}

input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    cursor: pointer;
    color: #f3ac14;
    height: 15px;
    width: 15px;
    background-image: url(/static/media/input_clear.65c83676.svg);
}
.fc-day-header span,
.fc-day-number,
.fc-list-item,
.fc-title,
.fc-title-insert,
.fc-time,
.fc-center h2 {
  color: white;
}

.fc-axis span {
  color: #ffffff90;
}

.fc-unthemed .fc-list-empty {
  background-color: #222222;
  color: white;
}

.fc-timeGridWeek-view .fc-day-header span {
  word-spacing: 100px;
}

.fc-timeGridWeek-view td,
.fc-row th,
.fc-head td,
.fc-head-container .fc-row,
.fc-day-grid .fc-row {
  border: 0;
  border-right-width: 0 !important;
}

.fc-row tr th {
  padding: 20px;
}

.fc-slats tr {
  border-top: 1px solid #ffffff40;
  border-bottom: 0;
  height: 15px;
}

.fc-time-grid-event {
  border-radius: 10px;
  padding: 5px 10px;
}

.fc-button {
  background-color: #464645;
  border-color: #464645;
}

.fc-button:hover,
.fc-button-primary:not(:disabled).fc-button-active,
.fc-button:focus,
.fc-button:disabled {
  background-color: var(--cassette-color);
  border-color: var(--cassette-color);
}

.fc-button-primary:not(:disabled):active,
.fc-button:disabled {
  background-color: #808080;
  border-color: #808080;
}

.fc-unthemed td.fc-today {
  background-color: #ffffff03 !important;
}

.fc .fc-axis {
  vertical-align: unset;
}

.fc-day-grid .fc-content-skeleton .fc-title .fc-title-insert {
  padding-left: 5px;
}

.fc-type-insert {
  border: 1px solid #464645 !important;
  border-right: 3px solid green !important;
}

.fc-type-override {
  border: 1px solid #464645 !important;
  border-right: 3px solid red !important;
}

.fc-day-grid .fc-content-skeleton a:not(.fc-draggable) .fc-title:before {
  content: "";
  display: inline-block;
  width: 10px;
  vertical-align: middle;
  height: 10px;
  border-radius: 7.5px;
  background-color: red;
  margin-right: 10px;
}

.fc-day-grid .fc-content-skeleton a:not(.fc-draggable) .fc-title-insert:before {
  content: "";
  display: inline-block;
  width: 10px;
  vertical-align: middle;
  height: 10px;
  border-radius: 7.5px;
  background-color: green;
  margin-right: 10px;
}

hr.fc-divider {
  border-width: 0 !important;
  background: #454546 !important;
  border-color: unset !important;
}

.fc-minor,
.fc-minor .fc-axis,
.fc-minor .fc-widget-content {
  height: 24px !important;
  border-bottom: 0 !important;
  border-top: 0 !important;
}

.fc-bgevent {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}

/*.fc-time-grid .fc-bgevent-container {
  z-index: 99;
}*/

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-content,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-list-heading td {
  border: 0 !important;
}

.fc-list-item:hover {
  background-color: gray;
}

.fc-override {
  background-color: #454546;
}

.fc-unthemed .fc-list-item:hover td {
  background-color: #45454640;
}


