.fc-day-header span,
.fc-day-number,
.fc-list-item,
.fc-title,
.fc-title-insert,
.fc-time,
.fc-center h2 {
  color: white;
}

.fc-axis span {
  color: #ffffff90;
}

.fc-unthemed .fc-list-empty {
  background-color: #222222;
  color: white;
}

.fc-timeGridWeek-view .fc-day-header span {
  word-spacing: 100px;
}

.fc-timeGridWeek-view td,
.fc-row th,
.fc-head td,
.fc-head-container .fc-row,
.fc-day-grid .fc-row {
  border: 0;
  border-right-width: 0 !important;
}

.fc-row tr th {
  padding: 20px;
}

.fc-slats tr {
  border-top: 1px solid #ffffff40;
  border-bottom: 0;
  height: 15px;
}

.fc-time-grid-event {
  border-radius: 10px;
  padding: 5px 10px;
}

.fc-button {
  background-color: #464645;
  border-color: #464645;
}

.fc-button:hover,
.fc-button-primary:not(:disabled).fc-button-active,
.fc-button:focus,
.fc-button:disabled {
  background-color: var(--cassette-color);
  border-color: var(--cassette-color);
}

.fc-button-primary:not(:disabled):active,
.fc-button:disabled {
  background-color: #808080;
  border-color: #808080;
}

.fc-unthemed td.fc-today {
  background-color: #ffffff03 !important;
}

.fc .fc-axis {
  vertical-align: unset;
}

.fc-day-grid .fc-content-skeleton .fc-title .fc-title-insert {
  padding-left: 5px;
}

.fc-type-insert {
  border: 1px solid #464645 !important;
  border-right: 3px solid green !important;
}

.fc-type-override {
  border: 1px solid #464645 !important;
  border-right: 3px solid red !important;
}

.fc-day-grid .fc-content-skeleton a:not(.fc-draggable) .fc-title:before {
  content: "";
  display: inline-block;
  width: 10px;
  vertical-align: middle;
  height: 10px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: red;
  margin-right: 10px;
}

.fc-day-grid .fc-content-skeleton a:not(.fc-draggable) .fc-title-insert:before {
  content: "";
  display: inline-block;
  width: 10px;
  vertical-align: middle;
  height: 10px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: green;
  margin-right: 10px;
}

hr.fc-divider {
  border-width: 0 !important;
  background: #454546 !important;
  border-color: unset !important;
}

.fc-minor,
.fc-minor .fc-axis,
.fc-minor .fc-widget-content {
  height: 24px !important;
  border-bottom: 0 !important;
  border-top: 0 !important;
}

.fc-bgevent {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}

/*.fc-time-grid .fc-bgevent-container {
  z-index: 99;
}*/

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-content,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-list-heading td {
  border: 0 !important;
}

.fc-list-item:hover {
  background-color: gray;
}

.fc-override {
  background-color: #454546;
}

.fc-unthemed .fc-list-item:hover td {
  background-color: #45454640;
}

